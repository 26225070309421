const state = {
    size:'',
}

const mutations = {
    SET_SIZE: (state, size) => {
        state.size = size
      },
}

export default {
    namespaced: true,
    state,
    mutations,
    // actions
  }