import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import AMap from 'vue-amap';
import animated from 'animate.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'lib-flexible/flexible'
Vue.use(AMap);
Vue.use(animated)
// import axios from 'axios';

// Vue.use(axios)
Vue.use(ElementUI);
Vue.config.productionTip = false
// axios.defaults.baseURL = 'http://timemeetyou.com:8889/api/private/v1/'
// Vue.prototype.$http = axios

router.beforeEach((to,from,next)=>{
  //全局前置守卫 指的是跳转之前把title替换了
  //从from跳转到to      
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  if (to.meta.title) {
  document.title=to.meta.title
  }
  next()
})

  // 初始化vue-amap
  AMap.initAMapApiLoader({
    // 高德key
    key: '3147d6df0fa0c4f20610b264b3318175',
    // 插件集合 （插件按需引入）
    plugin: ['AMap.Geolocation']
  });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
