<template>
  <div class="home">
    <div class="swipercss">
      <template>
        <div class="swiper">
          <el-carousel
            :interval="4000"
            arrow="never"
            loop
            @change="swiperChange"
            autoplay
            ref="carousel"
            indicator-position="none"
          >
            <el-carousel-item
              v-for="(item, index) in swiperList"
              :key="index + '1'"
              style="bacground: #072655"
            >
              <div class="pic">
                <!-- <div class="left">
                <img :src="item.src" alt="">
                
              </div> -->
                <div class="right">
                  <img :src="item.src" alt="" />
                </div>
                <img
                  :src="item.src1"
                  alt=""
                  style="width: 100vw; height: 100%"
                />
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="point">
            <div
              v-for="(item1, index1) in 3"
              class="pintItem"
              :key="index1 + '1'"
              @click="setActiveItem(index1)"
              @mouseenter="setActiveItem(index1)"
            >
              <div
                :class="[activeIndex == index1 ? 'pointActive' : 'pointColor']"
              ></div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!--产品-->
    <div class="product">
      <div class="title">
        <div>
          <img src="../img/home/left1.png" /> 专业、可靠、简洁、易懂的AI产品<img
            src="../img/home/right.png"
          /><img />
        </div>
        <div class="small" @click="product">查看全部产品服务</div>
      </div>
      <div class="main row">
        <div
          v-for="(item, index) in productList"
          :key="index + '1'"
          @click="productLocation(index)"
          class="col-md-4 col-sm-4 col-xs-4 col-lg-3"
        >
          <div v-if="index != 14">
            <div class="item">
              <div class="">
                <div><img :src="item.src" alt="" /></div>
                <div>{{ item.title }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="item" v-else style="width: 596px">
            <div><img :src="item.src" alt="" /></div>
            <div>{{ item.title }}</div>
          </div> -->
        </div>
        <div
          @click="productLocation(14)"
          class="col-md-4 col-sm-4 col-xs-4 col-lg-6"
        >
          <!-- <div class="item" v-if="index != 14">
            <div class="">
              <div><img :src="item.src" alt="" /></div>
            <div>{{ item.title }}</div>
            </div>
          </div> -->
          <!-- {
          src: require("../img/home/icon/icon15.png"),
          title: "查看全部产品服务",
        }, -->
          <div class="item">
            <div>
              <div><img src="../img/home/icon/icon15.png" alt="" /></div>
              <div>查看全部产品服务</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频轮播 -->
    <div
      class="videoSwiper"
      ref="videoSwiper"
      style="width: 100%; margin: 0 auto; height: 26rem"
    >
      <el-carousel
        :interval="4000"
        loop
        arrow="never"
        ref="videoCarousel"
        @change="videoswiperChange"
        autoplay
        style="height: 100%"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, index) in videoList" :key="index + '1'">
          <div class="pic" @click="videoPaly(item.src)">
            <img src="../img/home/Bitmap.png" alt="" />
            <div class="play">
              <img src="../img/home/play.png" alt="" />
            </div>
            <div class="text">
              {{ item.title }}
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="point">
        <div
          v-for="(item1, index1) in 3"
          class="pintItem"
          :key="index1 + '4'"
          @click="setActiveItemVideo(index1)"
          @mouseenter="setActiveItemVideo(index1)"
        >
          <div
            :class="[currentVideo == index1 ? 'pointActive' : 'pointColor']"
          ></div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="detialPlayVideo"
      @close="closePlay"
      destroy-on-close
      :width="dialogWidth"
    >
      <video
        ref="video"
        controls
        @click="clickVideo"
        style="width: 100%; height: 100%; object-fit: fill"
        :src="videosrc"
      ></video>
    </el-dialog>

    <!--精彩-->
    <div class="wonderful">
      <div class="title">
        <div>
          <img src="../img/home/left1.png" /> 总有精彩，值得期待<img
            src="../img/home/right.png"
          />
        </div>
        <div @click="seemore">查看全部新闻资讯</div>
      </div>
      <div class="row main">
        <div
          class="col-md-4 col-sm-4 col-xs-6 col-lg-3"
          v-for="(item, index) in topNews"
          :key="index + '8'"
          @click="detail(index + 1)"
        >
          <div class="item">
            <img :src="item.src" alt="" />
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="text hidden-xs">
                {{ item.text }}
              </div>
              <div class="date">
                <div>{{ item.date }}</div>
                <div class="arrow">
                  <img src="../img/home/rightArrows.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="item" v-for="(item,index) in bottomNews" :key =index @click="detail(index+3)">
          <div class="right"> 
            <div>{{item.title}}</div>
            <div class="date"><div>{{item.date}}</div><div class="arrow"><img src="../img/home/rightArrows.png" alt=""></div></div>
          </div>
          <img :src="item.src" alt="">
        </div> -->
      </div>
    </div>

    <!--战略合作伙伴-->
    <div class="cooperation">
      <div class="title">
        <div>战略合作伙伴</div>
        <div>能用众力，则无敌于天下矣；能用众智，则无畏于圣人矣</div>
      </div>
      <div class="main">
        <img src="../img/home/cooperation.png" alt="" />
      </div>
    </div>

    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      dialogWidth: "50%",
      dialogVisible: false,
      // swiperList:[{src:require('../img/home/bgtext1.png'),src1:require('../img/home/bgimg1.png'),},
      // {src:require('../img/home/bgtext2.png'),src1:require('../img/home/bgimg2.png'),},
      // {src:require('../img/home/bgtext3.png'),src1:require('../img/home/bgimg3.png'),}],

      swiperList: [
        {
          src: require("../img/home/banner-wz.png"),
          src1: require("../img/home/banner-bg.png"),
        },
        {
          src: require("../img/home/banner-wz02.png"),
          src1: require("../img/home/banner-bg.png"),
        },
        {
          src: require("../img/home/banner-wz03.png"),
          src1: require("../img/home/banner-bg.png"),
        },
      ],

      topNews: [
      {
          index:0,
          title: "家长可实时监控！襄城校园食堂进入“直播”时代",
          flag: true,
          src: require("../img/pic/title/20.png"),
          date: "2023年05月31日",
          text: "近日，襄城区行政审批局大数据中心联合襄城区教育局共同打造的襄城区学校食品安全监管服务平台，通过互联网+明厨亮灶，提高食品安全管理水平，实现学校食品安全监管管理及时、有效、可追溯。",
        },
      {
          index:1,
          title: "湖北鲲鹏芯荣获《2022年度昇腾AI创新大赛》银奖",
          flag: true,
          src: require("../img/pic/title/19.jpg"),
          date: "2022年9月20日",
          text: "2022年9月20日，以“数智未来，因你而来”为主题的武汉人工智能·昇腾AI创新大赛湖北赛区总决赛在武汉光谷皇冠假日酒店成功举办。基于昇腾MindX SDK的高速路视频结构化联合方案、基于昇思MindSpore搭建的地事通SaaS服务应用、基于昇思MindSpore的城市变化遥感监测平台、基于昇腾的乘客动态监测预警联合方案等47个优秀的AI创新项目亮相决赛现场，共同展现出“湖北AI实力”",
        },
        {
          index:2,
          title: "省市场监管局专题调研社会餐饮及配餐企业的管理",
          flag: true,
          src: require("../img/pic/title/17.jpg"),
          date: "2022年7月11日",
          text: "2022年7月11日，省市场监管朱钦炳，副局长邓仲林，（襄州区教育局，市场监管局王迪，襄州区市监局黄建国局长，城运领导陪同）到襄州区城运调研。主要问题集中在社会餐饮及配餐企业的管理。",
        },
        {
          index:3,
          title: "调研中央厨房 助推团餐产业",
          flag: true,
          src: require("../img/pic/14/2.jpg"),
          date: "2022年6月20日",
          text: "6月16日下午，农工党襄阳市委会调研组、襄阳市学生资助和学校后勤服务指导中心和襄阳市营养学会联合召开襄阳市“中央厨房”产业专题调研座谈会。座谈会邀请襄阳多家中央厨房企业代表、部分学校食堂后勤负责人、襄阳市学校食品安全监管服务平台负责人参加。",
        },
        // {
        //   index:3,
        //   title: "聚焦城市运行管理“急难愁盼” 探索城市现代治理“襄州方案”",
        //   flag: true,
        //   src: require("../img/pic/13/3.png"),
        //   date: "2022年6月13日",
        //   text: "为推进全市“一网统管”建设，通过组织开展学习借鉴成效显著的地区经验，形成“比学赶超”良好氛围，6月10日，襄州区城市运行管理中心将已经投入使用的校园“明厨亮灶”特色应用场景向与会者进行了介绍和实际演练，襄阳市人大常委会副主任吉虹，襄州区领导张双成、王宏举、刘德玉、江海军，各县（市、区）、开发区分管领导及城运中心主任参加活动。",
        // },
      //  {
      //     index:3,
      //     title: "襄州：“智慧之眼”守护师生“舌尖上的安全”",
      //     flag: true,
      //     src: require("../img/pic/title/15.png"),
      //     date: "2022年6月13日",
      //     text: "本报讯（全媒体记者 胡采棣 秦小芳 实习生 杨雨）通过安装在学校食堂操作间的高清摄像头，配餐制作的全部流程一览无余；依托先进的物联网感知设备，仓储、配送、烹饪等各个环节的异常情况即时传入“一网统管”系统后台；将AI智能算法植入监控系统，操作人员体温异常、未戴口罩等细节都能精准捕捉、实时预警……6月10日，在襄州区城运中心记者看到，在“互联网+明厨亮灶”特色应用场景中，工作人员可借助智慧化“食堂之眼”，随时随地了解全区8所学校食堂的运转情况，全面排查风险漏洞、纠正不规范行为，切实守护师生“舌尖上的安全”。",
      //   },
        // {
        //    index:3,
        //   title: "校园食品安全监管平台纳入政府“一网统管”",
        //   flag: true,
        //   src: require("../img/pic/title/12.jpg"),
        //   date: "2022年6月10日",
        //   text: "6月10日，襄阳市校园“明厨亮灶”特色应用场景现场会在襄州区城市运行管理中心指挥大厅成功举行。襄阳市人大常委会副主任吉虹、襄州区人大常委会主任张双成，各县（市、区）、开发区分管领导及城运中心主任共60多名领导出席。",
        // },
        // {
        //   title: "湖北电视台专题报道！",
        //   flag: true,
        //   src: require("../img/pic/title/1.jpg"),
        //   date: "2021年11月20日",
        //   text: "近年来，学校食堂服务保障学生不到位问题不断进入到人们的视野，襄阳市纪委监委立足职责定位，把中小学校食堂服务保障学生不到位问题作为教育领…",
        // },
        // {
        //   title: "全面推进！接入银针平台！",
        //   flag: true,
        //   src: require("../img/pic/title/2.jpg"),
        //   text: "以习近平总书记关于食品安全“四个最 严”为指导，深入贯彻落实党和国家关 于食品安全的相关文件要求，强化学校 食品安全主体责任的落实，充分运用…",
        //   date: "2021年11月20日",
        // },
        // {
        //   title: "东津新区：学校食堂“互联网+明厨亮灶”推进会召开",
        //   flag: false,
        //   src: require("../img/pic/title/3.jpg"),
        //   text: "11月19日上午，东津新区召开了学校食堂“互联网+明厨亮灶”推进会，会议由区教育办主任胡勇主持，全区中小学(幼儿园)校长(园长)、后勤主任参加了会议;区市场监管局、区教育办相关领导出席了会议。",
        //   date: "2021年11月19日",
        // },
        // {
        //   title: "我市100所试点学校今年底将实现“互联网＋明厨亮灶",
        //   flag: false,
        //   src: require("../img/pic/title/4.jpg"),
        //   date: "2021年11月15日",
        //   text: " 11月11日，全市学校食堂“互联网＋明厨亮灶”工作推进现场会在市三十六中举办。会上透露，今年年底前，全市所有学校食堂（含私立学校）将在襄阳市学校食品安全监管服务平台上注册并录入基础信息；今年年底前将完成100所试点学校的“互联网＋明厨亮灶”建设和接入平台任务。",
        // },
      ],
      bottomNews: [],
      currentVideo: 0,
      videosrc: "",
      videoList: [
        {
          title: "食品安全监管服务平台",
          src: require("../img/swiper/swiper.mp4"),
        },
        { title: "智慧校园平台", src: require("../img/swiper/swiper1.mp4") },
        { title: "阳光作业平台", src: require("../img/swiper/swiper2.mp4") },
      ],
      detialPlayVideo: false,
      activeIndex: 0,
      productList: [
        {
          src: require("../img/home/icon/icon1.png"),
          title: "“互联网+明厨亮灶”平台",
        },
        { src: require("../img/home/icon/icon2.png"), title: "智慧校园平台" },
        { src: require("../img/home/icon/icon3.png"), title: "教育大数据平台" },
        {
          src: require("../img/home/icon/icon4.png"),
          title: "心理健康监测平台",
        },
        { src: require("../img/home/icon/icon5.png"), title: "阳光作业平台" },
        {
          src: require("../img/home/icon/icon6.png"),
          title: "考试仿真系统SAAS平台",
        },
        {
          src: require("../img/home/icon/icon7.png"),
          title: "智能题库平台",
        },
        {
          src: require("../img/home/icon/icon8.png"),
          title: "舆情分析、监控平台",
        },
        {
          src: require("../img/home/icon/icon9.png"),
          title: "排水系统智慧管控平台",
        },
        {
          src: require("../img/home/icon/icon10.png"),
          title: "智慧交通、停车平台",
        },
        {
          src: require("../img/home/icon/icon11.png"),
          title: "手写体识别算法引擎",
        },
        {
          src: require("../img/home/icon/icon12.png"),
          title: "自适应学习引擎",
        },
        {
          src: require("../img/home/icon/icon13.png"),
          title: "英语作文机器批阅引擎",
        },
        {
          src: require("../img/home/icon/icon14.png"),
          title: "英语口语机器评价引擎",
        },
      ],
      systemList: [
        {
          src: require("../img/home/cp1.png"),
          title: "精准教学系统",
          des: "逻辑可见，精准匹配",
        },
        {
          src: require("../img/home/cp2.png"),
          title: "阳光作业系统",
          des: "轻松上手，方便快捷",
        },
        {
          src: require("../img/home/cp3.png"),
          title: "大数据检测系统",
          des: "数据统计，检测顺畅",
        },
        {
          src: require("../img/home/cp5.png"),
          title: "智慧题库系统",
          des: "随时服用，效率翻倍",
        },
        {
          src: require("../img/home/cp4.png"),
          title: "食品安全监管系统",
          des: "随时随地，食安监管",
        },
      ],
      cooperationList: [
        require("../img/home/icon.png"),
        require("../img/home/icon2.png"),
        require("../img/home/icon3.png"),
        require("../img/home/icon4.png"),
        require("../img/home/icon5.png"),
        require("../img/home/icon.png"),
        require("../img/home/icon2.png"),
        require("../img/home/icon3.png"),
        require("../img/home/icon4.png"),
        require("../img/home/icon5.png"),
      ],
      playVideo: false,
    };
  },
  created() {
    if (navigator.userAgent.indexOf("Firefox") >= 0) {
      // alert('你是使用Firefox')
      var mydiv = document.getElementsByClassName("home");
      // home.style.height= calc( 100% - 82px);
    } else {
    }
  },
  mounted() {
    this.setRem();
    window.onresize = function () {
      var that = this;
      that.setRem();
    };
    // this.$refs.video.addEventListener('play', ()=> { //播放开始执行的函数
    //     console.log("开始播放");
    //     this.playVideo = true;
    // });
    // this.$refs.video.addEventListener('pause', ()=> { //播放开始执行的函数
    //     console.log("暂停");
    //     setTimeout(() => {
    //       this.playVideo = false
    //     },2000)
    // });
    this.$nextTick(() => {
      // setTimeout(() => {
      //    this.$refs.video.play();
      // },3000)
      // this.playVideo=false
    });
  },
  destroyed() {
    // window.removeEventListener('scroll',this.scroll,  false);
  },
  computed: {},
  methods: {
    setRem() {
      // UI设计稿的宽度
      var uiWidth = 1200;
      var that = this;
      // 移动端屏幕宽度
      var winWidth = document.documentElement.clientWidth;
      if (winWidth < 500) {
        that.dialogWidth = "90%";
      } else {
        that.dialogWidth = "50%";
      }
      // console.log(1111, that.dialogWidth);
    },
    closePlay() {
      this.detialPlayVideo = false;
      // this.$refs.video.pause()
    },
    videoPaly(item) {
      this.detialPlayVideo = true;
      this.videosrc = item;
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    },
    setActiveItemVideo(index) {
      this.$refs.videoCarousel.setActiveItem(index);
      this.activeIndex = index;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    seemore() {
      this.$router.push({ path: "/index/news" });
    },
    scroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (
        scrollTop > this.$refs.video.offsetHeight + 1150 ||
        scrollTop < this.$refs.video.offsetHeight + 500
      ) {
      }
    },
    productLocation(index) {
      if (this.productList.length == index) {
        this.$router.push({ path: "/index/product" });
        return;
      }
      this.$router.push({
        path: "/index/productDetail",
        name: "productDetail",
        params: {
          productIndex: index,
        },
      });
    },
    product() {
      this.$router.push({ path: "/index/product" });
    },
    clickVideo() {},
    detail(index) {
      this.$router.push({
        path: "/index/newsDetail",
        name: "newsDetail",
        params: {
          newsIndex: index,
        },
      });
    },
    videoswiperChange(e) {
      this.currentVideo = e;
    },
    swiperChange(index) {
      this.activeIndex = index;
    },
  },
  watch: {},
};
</script>

<style lang ="scss" scoped>
/deep/.el-dialog__wrapper {
  background: rgba(250, 250, 250, 0.95);
}
/deep/.el-dialog__header,
/deep/.el-dialog__body {
  background: #cccccc;
  z-index: 999999999;
}
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: white;
}
.home {
  /* margin-top:-82px; */
}
.swiper {
  /* display: none; */
  width: 100%;
  position: relative;
  .point {
    opacity: 0.9;
    position: absolute;
    bottom: 0;
    display: flex;
    margin-right: 10px;
    z-index: 9999999;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 1.5rem;
    margin: auto;
    .pintItem {
      display: flex;
      width: 2.5rem;
      min-width: 30px;
      background: #ffffff;
      height: 0.2px;
      min-height: 3px;
      /* opacity: 0.2; */
      margin-right: 10px;
      cursor: pointer;
      .pointColor {
        /* background: #EA5539; */
        width: 100%;
        height: 4px;
        /* animation: progressAnimationStrike 6s; */
      }
      .pointActive {
        background: #ff9200;
        width: 100%;
        height: 4px;
        animation: progressAnimationStrike 4.1s linear;
      }
      @keyframes progressAnimationStrike {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
    }
  }
  /deep/ .el-carousel {
    /* position: relative; */
    /* z-index: 99999; */
    /* max-width: 1200px; */
    width: 100%;
    margin: 0 auto !important;
    height: 35rem !important;
    background: linear-gradient(120deg, #072655 0%, #0b5593 99%);
    .pic {
      /* position: relative; */
      max-width: 1200px;
      width: 100%;
      height: 100%;
      box-shadow: 0px 7px 2px 0px rgba(246, 246, 246, 0.2);
      .left {
        position: absolute;
        top: 300px;
        width: 548px;
        height: 108px;
        left: calc((100vw - 1200px) / 2);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        /* width: 1200px; */
        /* height: 326px; */
        max-width: 1200px;
        width: 100%;
        left: 0;
        right: 0;
        margin: auto;
        /* top:290px; */
        bottom: -6px;
        /* top: 200px; */
        /* right: calc((100vw - 1200px) / 2); */
        position: absolute;
        img {
          /* display: none; */
          /* width: 100px;
          height: 100px; */
          width: 100%;
          /* height: 7.2rem; */
        }
      }
      img {
        width: 100vw;
        height: 100%;
      }
    }
  }
}
.center-vertical {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}
.cooperation {
  max-width: 1200px;
  width: 100%;
  /* width: 1200px; */
  /* height: 7rem; */
  margin: 0 auto;
  /* margin-top: 20px; */
  padding-bottom: 20px;
  .title {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    div:nth-child(1) {
      /* width: 160px; */
      height: 25px;
      font-size: 1.9rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      /* line-height: 32px; */
    }
    div:nth-child(2) {
      padding-top: 2.5rem;
      /* width: 471px; */
      height: 18px;
      font-size: 0.9rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 1.6rem;
    }
  }
  .main {
    padding-top: 4rem;
    img {
      width: 100%;
    }
  }
}
.product {
  width: 100%;
  /* height: 960px; */
  background: #f5f7fa;
  padding-bottom: 3.5rem;
  .title {
    display: flex;
    flex-direction: column;
    padding-top: 3.2rem;
    /* padding-top: 70px; */
    width: 100%;
    div:nth-child(1) {
      /* width: 404px;
      height: 25px; */
      font-size: 1.8rem;
      font-family: PingFang SC;
      font-weight: 500;
      /* color: #FFFFFF; */
      color: #ff9200;
      /* line-height: 32px; */
      opacity: 1;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img:nth-child(1) {
        margin-right: 1.5rem;
        width: 3rem;
        min-width: 30px;
      }
      img:nth-child(2) {
        margin-left: 1.5rem;
        width: 3rem;
        min-width: 30px;
      }
    }
    .small {
      /* width: 133px;
      height: 16px; */
      /* margin-top:30px; */
      width: 150px;
      height: 16px;
      font-size: 0.84rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin: 0 auto;
      /* line-height: 32px; */
      cursor: pointer;
      margin-top: 0.9rem;
    }
  }
  .small:hover {
    transform: scale(1.02, 1.02);
    color: #0133ff;
  }
  .main {
    padding-top: 3rem;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div {
      padding-left: 0;
      padding-right: 0;
      padding: 1px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .item {
      width: 100%;
      height: 8rem;
      background: #ffffff;
      position: relative;
      margin: 2px;
      img {
        position: absolute;
        left: 22px;
        top: 20px;
        width: 38px;
        height: 36px;
      }
      div:nth-child(2) {
        z-index: 200;
        position: absolute;
        height: 19px;
        font-size: 0.9rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-align: left;
        color: #333333;
        left: 20px;
        bottom: 20px;
      }
    }
    .item:hover {
      background: #edf5ff;
      cursor: pointer;
      /* img {
        opacity: 1;
      } */
      img {
        transform: scale(1.1, 1.1);
      }
      div:nth-child(2) {
        /* color: #ffffff; */
      }
    }
  }
}

.videoSwiper {
  cursor: pointer;
  /* display: none; */
  width: 100%;
  height: 6rem;
  /* height: 520px; */
  /* background-image: url('../img/home/bg1.png'); */
  position: relative;
  .point {
    /* opacity: 0.2; */
    position: absolute;
    bottom: 0;
    display: flex;
    margin-right: 10px;
    /* z-index: 99; */
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 1.5rem;
    margin: auto;
  }
  /* opacity: 0.9; */
  .pic {
    /* position: relative; */
    width: 100%;
    height: 100%;
    box-shadow: 0px 7px 2px 0px rgba(246, 246, 246, 0.2);
    position: relative;
    .play {
      position: absolute;
      left: 0;
      top: -5rem;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 50px;
      height: 50px;
      img {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      }
    }
    .text {
      position: absolute;
      left: 0;
      top: 7rem;
      bottom: 0px;
      right: 0;
      margin: auto;
      width: 349px;
      height: 23px;
      font-size: 1.2rem;
      font-family: PingFang SC;
      font-weight: 400;
      /* color: #333333; */
      color: #ffffff;
      line-height: 32px;
      /* bottom: -120px; */
    }
    img {
      /* display: flex;
        justify-content: center;
        align-items: center; */
      height: 100%;
      width: 100%;
    }
  }
  .point {
    /* opacity: 0.2; */
    position: absolute;
    bottom: 0;
    display: flex;
    margin-right: 10px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 1.5rem;
    margin: auto;
    .pintItem {
      display: flex;
      width: 2.5rem;
      min-width: 30px;
      background: #ffffff;
      height: 0.2rem;
      min-height: 3px;
      opacity: 0.9;
      margin-right: 10px;
      cursor: pointer;
      .pointActive {
        background: #ff9200;
        width: 100%;
        height: 4px;
        animation: progressAnimationStrike 4s linear;
      }
      @keyframes progressAnimationStrike {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
    }
  }
}

.wonderful {
  width: 100%;
  padding-bottom: 3rem;
  background: #f5f7fa;
  .title {
    display: flex;
    flex-direction: column;
    padding-top: 3.5rem;
    div:nth-child(1) {
      font-size: 1.8rem;

      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff9200;
      line-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* opacity: 0.6; */
      img:nth-child(1) {
        padding-top: -10px;
        /* margin-right:1.5rem; */
        /* margin-bottom:- 20px; */

        margin-right: 1.5rem;
        width: 3rem;
        min-width: 30px;
      }
      img:nth-child(2) {
        width: 3rem;
        margin-left: 1.5rem;
        min-width: 30px;
      }
    }
    div:nth-child(2) {
      width: 150px;
      margin: 0 auto;
      margin-top: 1.5rem;
      font-size: 0.84rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      cursor: pointer;
    }
    div:nth-child(2):hover {
      transform: scale(1.02, 1.02);
      color: #0133ff;
    }
  }
  .main {
    padding-top: 0.4rem;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    /* display: flex;
    flex-wrap: wrap; */
    /* justify-content: space-around; */
    /* justify-content: center; */
    .item {
      margin-top: 30px;
      /* width: 100%; */
      /* height: 360px; */
      background: #ffffff;
      border-radius: 8px;
      padding-bottom: 30px;
      position: relative;
      & > img {
        width: 100%;
        /* height: 3rem; */

        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .right {
        /* height: 160px; */
        /* opacity: 0.3; */
        text-align: left;
        padding: 0.65rem 0.8rem 0.6rem 0.8rem;
        position: relative;
        .text {
          margin-top: 14px;
          /* height: 80px; */
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;

          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          /* padding-bottom: 30px; */
        }
        .title {
          padding-top: 0 !important;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 0.8rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          line-height: 22px;

          color: #333333;
          /* opacity: 0.8; */
        }
        .date {
          width: calc(100% - 1.6rem);
          position: absolute;
          display: flex;
          justify-content: space-between;
          bottom: -20px;
          font-size: 0.7rem !important;
          font-family: PingFang SC;
          font-weight: 400;
          opacity: 0.8;
          align-items: center;
          .arrow {
            /* position: absolute; */
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 0;
            height: 20px;
            /* font-size: 14px; */
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff9200;
            line-height: 20px;
            width: 30px;

            /* right: -132px; */
            img {
              min-height: 12px;
              height: 0.75rem;
              width: 0.95rem;
              min-width: 16px;
            }
          }
        }
      }
    }
    .item:hover {
      cursor: pointer;
      opacity: 1;
      /* .right {
        opacity: 1;
        .date {
          .arrow {
            display: block;
          }
        }
      } */
      transform: scale(1.06, 1.1);
      background: #edf5ff;
    }
    .item:nth-child(even) {
      margin-left: 4px;
    }
  }
}

.introduce {
  text-align: left;
  background-repeat: no-repeat;
  background: url(../img/home/bg.png);
  width: 100%;
  /* min-width: 1200px; */
  height: 502px;
  margin: 0 auto;
  margin-top: 86px;
  display: flex;
  .container {
    height: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    img {
      margin-top: 0px;
      /* width: 550px; */
      /* height: 360px; */
      height: 100%;
      border: 2px solid rgba(255, 255, 255, 0.14);
      border-radius: 10px;
    }
    .right {
      flex: 1;
      height: 200px;
      margin-left: 25px;
      display: flex;
      /* justify-content: space-around; */
      flex-direction: column;
      margin-top: 0px;
      h3 {
        /* font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        padding-top: -10px; */
        /* line-height: 95px; */
        /* width: 215px; */
        height: 17px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .title {
        width: 100%;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-top: 35px;
        margin-left: 0;
        margin-bottom: 30px;
      }
      .text {
        /* width: 581px; */
        height: 94px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 38px;
      }
    }
  }
}
.el-carousel {
  /* min-width: 1200px; */
  width: 100%;
  /* height: 65vh; */
  /deep/ .el-carousel__container {
    height: 100% !important;
  }
}

@media screen and (max-width: 500px) {
  .product .col-lg-3:nth-child(3n) {
    padding-left: 0;
    padding-right: 5px !important ;
  }
  .wonderful .col-xs-6 {
    padding-right: 7px !important;
  }
  .wonderful .col-xs-6:nth-child(2n) {
    padding-left: 7px !important;
    padding-right: 15px !important;
  }
  .product .title div:nth-child(1),
  .wonderful .title div:nth-child(1) {
    font-size: 16px !important;
  }
  .wonderful .main .item img {
    height: 109px;
  }
  .wonderful .main .item {
    padding-bottom: 24px;
    margin-top: 10px;
  }
  .videoSwiper .pic .play {
    top: -40px;
  }
  .videoSwiper .pic .text {
    top: 100px !important;
  }
  .product .main .item {
    height: 8rem !important;
  }
  .product .main .item & > div {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0.5rem;
  }
  .product .main .item img {
    position: static !important;
    width: 18px;
    height: 18px;
  }
  .product .main .item div:nth-child(2) {
    position: static;

    white-space: nowrap; /*一行显示*/

    overflow: hidden; /*超出部分隐藏*/

    text-overflow: ellipsis;
    width: 100%;
  }
}
@media screen and (max-width: 780px) {
  .product .main .item {
    height: 14rem !important;
  }
  .wonderful .main .item img {
    /* height: 16rem; */
  }
}
@media screen and (max-width: 500px) {
  /deep/.el-dialog{
    margin-top:28vh !important;
  }
}


</style>