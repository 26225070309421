import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/index/home',
  },
  {
    path: '/index',
    name: 'index',
    meta:{title:"鲲鹏芯-产品介绍"},
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    children:[
      {
        path: 'product',
        name: 'product',
        component: () => import(/* webpackChunkName: "about" */ '../views/product.vue'),
        meta:{title:"鲲鹏芯-产品介绍"}
      },
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta:{title:"鲲鹏芯-主页"},
      },
      {
        path: 'news',
        name: 'news',
        component: () => import(/* webpackChunkName: "about" */ '../views/news.vue'),
        meta:{title:"鲲鹏芯-新闻资讯"},
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
        meta:{title:"鲲鹏芯-关于我们"},
      },
      {
        path: 'newsDetail',
        name: 'newsDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/newsDetail.vue'),
        meta:{title:"鲲鹏芯-新闻详情"},
      },
      {
        path: 'productDetail',
        name: 'productDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/productDetail.vue'),
        meta:{title:"鲲鹏芯-产品详情"},
      },
    ]
  },
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from,savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


export default router
